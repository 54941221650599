
import { defineComponent } from "vue";
import { RestResources } from "../../../rest-resource";
import ResourceList from "../../../components/resources/resources-list.vue";

import productCategoryDetails from "../product-categories/product-categories-details.vue";
import ResourceDropdown from "../../../components/resources/resource-dropdown.vue";
const ApiResources = RestResources();

export default defineComponent({
  name: "ProductCategories",
  components: {
    ResourceList,
    productCategoryDetails,
  },
  data() {
    return {
      rowData: [],
      loading: false,
      editType: "fullRow",
      frameworkComponents: {},
      paginationData: {},
      columnDefs: [
        {
          headerName: "Category Name",
          field: "name",
          sortable: true,
          filter: true,
          editable: true,
          resizable: true,
        },
        {
          headerName: "Category Rank",
          field: "rank",
          sortable: true,
          filter: true,
          editable: true,
          resizable: true,
        },
        {
          headerName: "Active/Inactive",
          field: "isActive",
          valueGetter: (params: any) => {
            return params.data.isActive ? "Active" : "Inactive";
          },
          sortable: true,
          filter: true,
          editable: true,
          resizable: true,
        },
        {
          headerName: "Updated Time",
          field: "updatedTime",
          sortable: true,
          filter: true,
          resizable: true,
          valueGetter: (params: any) => {
            return params.data.updatedTime || "";
          },
        },
        {
          headerName: "Created Time",
          field: "createdTime",
          sortable: true,
          filter: true,
          resizable: true,
          valueGetter: (params: any) => {
            return params.data.createdTime || "";
          },
        },
        {
          headerName: "Actions",
          pinned: "right",
          width: 160,
          cellRenderer: this.renderActions.bind(this),
          cellStyle: { "text-align": "justify" },
        },
      ],
      btn_options: {
        button: "Add",
        icon: "fa fa-plus",
        class: "btn bg-theme float-end text-white",
      },
    };
  },
  async mounted() {
    this.frameworkComponents = {
      ResourceDropdown: ResourceDropdown,
    };
    this.loading = true;
    this.getCategories();
  },
  methods: {
    async getCategories(page = 1, search = "", pageLink = "", date: any = {}) {
      let url = pageLink
        ? pageLink
        : `${
            process.env.VUE_APP_ROOT_API
          }/product-categories?page=${page}&page_size=${
            this.$store.state.page_size
          }${search ? `&search=${search}` : ""}${
            date.start ? `&created_time__gte=${date.start}` : ""
          }${date.end ? `&created_time__lte=${date.end}` : ""}`;
      try {
        let productCategories = await ApiResources.getListResource(url).list();
        this.loading = false;
        let { result, ...paginationData } = productCategories.response.data;
        this.rowData = result;
        this.paginationData = paginationData;
      } catch (err: any) {
        this.loading = false;
        this.displayMessage(err.response.data.message, "danger");
      }
    },
    async editResource(data: any) {
      console.log("data", data);
      let updateResource = await ApiResources.ProductCategories.wrap(
        `/${data._id}`
      );
      let result = await updateResource.patch(data.resource);
      this.displayMessage(
        result.data.message,
        result.status == 200 ? "white" : "warning"
      );
      this.getCategories();
    },
    getPageSize() {
      return this.$store.state.dropdown_page_size;
    },
    renderActions(params: any) {
      const eDiv = document.createElement("div");
      const showCategory: any = this.showCategory;
      const confirmDelete: any = this.confirmDelete;

      eDiv.innerHTML =
        '<span class="productCategories-actions"><button class="btn btn-simple-e" title="Edit Product Categories"><i class="fas fa-edit"></i></button><button class="btn btn-simple-d" title="Delete Product Categories"><i class="fas fa-trash-alt"></i></button></span>';
      var eButtonD = eDiv.querySelectorAll(".btn-simple-d")[0];

      var eButtonE = eDiv.querySelectorAll(".btn-simple-e")[0];
      eButtonE.addEventListener("click", function () {
        showCategory(params.data);
      });

      eButtonD.addEventListener("click", function () {
        confirmDelete(params.data);
      });
      return eDiv;
    },

    async confirmDelete(data: any) {
      this.showPopup({
        title: "Delete",
        text: `Are you sure, you want to delete ${data.name} ?`,
        confirmYes: "Delete",
        confirmNo: "Cancel",
        _id: data._id,
      });
    },
    async showCategory(data: any) {
      (this.$refs["resource-details"] as any).resetForm();
      let result;
      if (data) {
        let resourceEndPoint = await ApiResources.ProductCategories.wrap(
          `/${data._id}`
        );
        result = await resourceEndPoint.get();
      }
      (this.$refs["resource-details"] as any).showDetailModal(
        data ? result.data || {} : {}
      );
    },
    async deleteResource(_id: any) {
      let resourceEndPoint = await ApiResources.ProductCategories.wrap(
        `/${_id}`
      );
      let result = await resourceEndPoint.delete();
      this.displayMessage(
        result.data.message,
        result.status == 200 ? "white" : "warning"
      );
      this.getCategories();
    },
  },
});

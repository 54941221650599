import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "product-category-details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_resource_detail = _resolveComponent("resource-detail")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.productCategory)
      ? (_openBlock(), _createBlock(_component_resource_detail, {
          key: 0,
          title: _ctx.title,
          schema: _ctx.schema,
          submitButton: _ctx.submitButton,
          onOnsubmit: _ctx.onsubmit,
          ref: "ResourceDetail"
        }, null, 8, ["title", "schema", "submitButton", "onOnsubmit"]))
      : _createCommentVNode("", true)
  ]))
}
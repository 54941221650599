
import { defineComponent } from "vue";
import { requiredValidator } from "../../../validators";
import { RestResources } from "../../../rest-resource";
import ResourceDetail from "../../../components/resources/resource-details.vue";
const ApiResources = RestResources();

export default defineComponent({
  name: "product-category-details",
  components: {
    ResourceDetail,
  },
  data() {
    return {
      validatedForm: {},
      button: "Add",
      icon: "fa fa-plus",
      title: "Product Category Details",
      productCategory: {},
      schema: [],
      submitButton: {
        class: "btn bg-theme bt-submit mt-3 float-start",
        value: "Submit",
        loading: "Submiting...",
      },
    };
  },
  methods: {
    async showDetailModal(data: any) {
      this.resetForm();
      let fields = await this.setFormData(data);
      this.schema.push(...fields);
      this.productCategory = data;
      (this.$refs["ResourceDetail"] as any).toggleDetailModal();
    },
    resetForm() {
      this.schema.length = 0;
    },
    async onsubmit(data: any) {
      data.forEach((element: any) => {
        this.validatedForm[element.name] = element.value;
      });
      let formData = new FormData();
      formData.append("name", this.validatedForm.name);
      formData.append("rank", this.validatedForm.rank);

      for (var i = 0; i < this.validatedForm.files.length; i++) {
        let file = this.validatedForm.files[i];
        formData.append("files", file);
      }

      try {
        let result;
        let resourceEndPoint = ApiResources.ProductCategories.wrap(
          `/${this.productCategory._id ? this.productCategory._id : ""}`
        );
        if (this.productCategory._id) {
          result = await resourceEndPoint.patch(formData);
          this.clearForm(false);
        } else {
          result = await resourceEndPoint.post(formData);
          this.clearForm();
        }
        this.displayMessage(
          result.data.message,
          result.status == 200 ? "success" : "warning"
        );
        this.$emit("getResources");
        (this.$refs["ResourceDetail"] as any).toggleDetailModal();
      } catch (err: any) {
        this.clearForm(false);
        this.displayMessage(err.response.data.message, "danger");
      }
    },
    clearForm(reset = true) {
      (this.$refs["ResourceDetail"] as any).$refs["generatedform"].resetForm(
        reset
      );
    },
    setFormData(data: any) {
      return [
        {
          fieldType: "InputField",
          placeholder: "Enter Name",
          label: "Name*",
          name: "name",
          type: "text",
          value: data.name ? data.name : "",
          class: "form-group col-md-12 mb-2 mt-3 text-secondary",
          defaultValue: "",
          validators: [
            { validator: requiredValidator, message: "Name is required" },
          ],
        },
        {
          fieldType: "InputField",
          placeholder: "Enter Rank",
          label: "Rank*",
          name: "rank",
          type: "number",
          value: data.rank ? data.rank : "",
          class: "form-group col-md-12 mb-2 mt-3 text-secondary",
          defaultValue: "",
        },
        {
          fieldType: "Fileupload",
          placeholder: "Images/Drawings",
          label: "",
          name: "files",
          type: "file",
          value: [],
          multiple: "multiple",
          class: "form-group col-md-12 mt-2 text-secondary text-start",
          style: "bg-white border",
          defaultValue: "",
        },
      ];
    },
  },
});
